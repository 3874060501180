import { render, staticRenderFns } from "./Coupon.vue?vue&type=template&id=e70b2dd6&scoped=true&"
import script from "./Coupon.vue?vue&type=script&lang=js&"
export * from "./Coupon.vue?vue&type=script&lang=js&"
import style0 from "./Coupon.vue?vue&type=style&index=0&id=e70b2dd6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e70b2dd6",
  null
  
)

export default component.exports